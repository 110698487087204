import { FormEventHandler, useState } from "react";
import HeaderTop from "../components/HeaderTop";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import logo from "../assets/logo-dark.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import serviceOne from "../assets/service-3.jpg";
import serviceTwo from "../assets/service-6.jpg";
import serviceThree from "../assets/service-7.jpg";

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    occupation: "",
    review: "",
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const inputChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const subscribe: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://app.nelzmgt.com/api/mailing-list", {
        email,
      });

      toast.success("Email address added to mailing list!");
      setEmail("");
    } catch (error: any) {
      if (error.response.data.error === "This user already exists") {
        toast.error("Email address already in mailing list");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const sendReview: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axios.post("https://app.nelzmgt.com/api/review", {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        occupation: formData.occupation,
        review: formData.review,
      });

      toast.success("Review sent successfully!");
      setLoading(false);
      setFormData((prevState) => ({
        ...prevState,
        name: "",
        email: "",
        phone: "",
        occupation: "",
        review: "",
      }));
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <HeaderTop />
      <div className="navigation">
        <div className="container">
          <div className="row">
            <div className="header_logo">
              <Link to="/">
                <img src={logo} alt="logo" className="logo" />
              </Link>
            </div>
            <div className="mobile-screen">
              <div id="navigation" className="small-screen">
                <div
                  id="menu-button"
                  className={`${menuOpen ? "menu-opened" : ""}`}
                  onClick={toggleMenu}
                />
                <ul
                  style={menuOpen ? { display: "block" } : { display: "none" }}
                  className={`${menuOpen ? "open" : ""}`}
                >
                  <li>
                    <Link className="active-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/consultation">Get Quote</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="desktop-screen">
              <div id="navigation">
                <ul>
                  <li>
                    <Link className="active-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-right">
              <Link to="/consultation" className="header_quate_right">
                Get a Quote{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="slides_wrapper">
        <OwlCarousel
          className="slider_home"
          items={1}
          nav
          dots={false}
          autoplay
          loop
          autoplayTimeout={8000}
          smartSpeed={1000}
          navText={[
            "<i className='fa fa-angle-left'></i>",
            "<i className='fa fa-angle-right'></i>",
          ]}
          mouseDrag
          touchDrag
        >
          <div className="single_slider slide_bg_1">
            <div className="slider_item_tb">
              <div className="slider_item_tbcell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-7 col-sm-8">
                      <h2>Elevate Your Property's Appeal</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="single_slider slide_bg_2">
            <div className="slider_item_tb">
              <div className="slider_item_tbcell">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-8 col-sm-offset-2">
                      <h2>
                        Spotless Spaces,
                        <br /> Satisfied Clients
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="single_slider slide_bg_3">
            <div className="slider_item_tb">
              <div className="slider_item_tbcell">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-8 col-sm-offset-2">
                      <h2>24/7 Support When You Need It</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
      <div className="promo_area">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <ul className="promo_list">
                <li className="promo_list__item">
                  <div className="icon icon_group" />
                  <div className="promo_list_title">Professional Staff</div>
                </li>
                <li className="promo_list__item">
                  <div className="icon icon_house" />
                  <div className="promo_list_title">
                    Safety &amp; Experience{" "}
                  </div>
                </li>
                <li className="promo_list__item">
                  <div className="icon icon_like_alt" />
                  <div className="promo_list_title">Fair Pricing</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="service_section">
        <div className="container">
          <div className="base-header">
            <h3> What we provide</h3>
            <p>
              Our comprehensive suite of services is designed to enhance the
              beauty, safety, and efficiency of your property, ensuring it
              leaves a lasting impression on your clients, tenants and
              employees.
            </p>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="col-md-3">
                <div className="row">
                  <div className="service_left">
                    <div className="service_left_list">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                        style={{ fontSize: "40px" }}
                      >
                        <path
                          fill="#038fd6"
                          d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"
                        />
                      </svg>
                      <Link to="/professional-commercial-landscaping">
                        <h4>
                          Professional Commercial & Residential Landscaping |
                          Property Maintenance
                        </h4>
                      </Link>
                      <p>
                        Our expert team of landscapers transforms outdoor spaces
                        into vibrant, well-maintained environments that create a
                        positive first impression.
                      </p>
                    </div>
                    <div className="service_left_list">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 576 512"
                        style={{ fontSize: "40px" }}
                      >
                        <path
                          fill="#038fd6"
                          d="M566.6 54.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192-34.7-34.7c-4.2-4.2-10-6.6-16-6.6c-12.5 0-22.6 10.1-22.6 22.6v29.1L364.3 320h29.1c12.5 0 22.6-10.1 22.6-22.6c0-6-2.4-11.8-6.6-16l-34.7-34.7 192-192zM341.1 353.4L222.6 234.9c-42.7-3.7-85.2 11.7-115.8 42.3l-8 8C76.5 307.5 64 337.7 64 369.2c0 6.8 7.1 11.2 13.2 8.2l51.1-25.5c5-2.5 9.5 4.1 5.4 7.9L7.3 473.4C2.7 477.6 0 483.6 0 489.9C0 502.1 9.9 512 22.1 512l173.3 0c38.8 0 75.9-15.4 103.4-42.8c30.6-30.6 45.9-73.1 42.3-115.8z"
                        />
                      </svg>
                      <Link to="/professional-commercial-cleaning">
                        <h4>
                          Professional Commercial & Residential Cleaning |
                          Janitorial Services
                        </h4>
                      </Link>
                      <p>
                        Our experienced cleaning professionals use
                        industry-leading techniques and environmentally friendly
                        products to deliver exceptional results.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="service_imgg">
                    <OwlCarousel
                      className="owl-carousel owl-theme"
                      id="ser"
                      autoplay
                      autoplaySpeed={5000}
                      smartSpeed={2000}
                      items={1}
                      loop
                      margin={10}
                      touchDrag
                      mouseDrag
                      nav
                      dots={false}
                      navText={[
                        "<i className='arrow_carrot-left'></i>",
                        "<i className='arrow_carrot-right'></i>",
                      ]}
                    >
                      <img src={serviceOne} alt="service" className="serv" />
                      <img src={serviceTwo} alt="service" className="serv" />
                      <img src={serviceThree} alt="service" className="serv" />
                    </OwlCarousel>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="row">
                  <div className="service_left_list service_right_list">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 640 512"
                      style={{ fontSize: "40px" }}
                    >
                      <path
                        fill="#038fd6"
                        d="M0 48C0 21.5 21.5 0 48 0H368c26.5 0 48 21.5 48 48V96h50.7c17 0 33.3 6.7 45.3 18.7L589.3 192c12 12 18.7 28.3 18.7 45.3V256v32 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H576c0 53-43 96-96 96s-96-43-96-96H256c0 53-43 96-96 96s-96-43-96-96H48c-26.5 0-48-21.5-48-48V48zM416 256H544V237.3L466.7 160H416v96zM160 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm368-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM176 80v48l-48 0c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V192h48c8.8 0 16-7.2 16-16V144c0-8.8-7.2-16-16-16H240V80c0-8.8-7.2-16-16-16H192c-8.8 0-16 7.2-16 16z"
                      />
                    </svg>
                    <Link to="/emergency-services">
                      <h4>
                        Professional Commercial & Residential Emergency Services
                        | 24/7 Proven Support
                      </h4>
                    </Link>
                    <p>
                      Our team is ready to respond to emergencies at any time,
                      ensuring your property remains secure and operational.
                    </p>
                  </div>
                  <div className="service_left_list service_right_list">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      style={{ fontSize: "40px" }}
                    >
                      <path
                        fill="#038fd6"
                        d="M418.4 157.9c35.3-8.3 61.6-40 61.6-77.9c0-44.2-35.8-80-80-80c-43.4 0-78.7 34.5-80 77.5L136.2 151.1C121.7 136.8 101.9 128 80 128c-44.2 0-80 35.8-80 80s35.8 80 80 80c12.2 0 23.8-2.7 34.1-7.6L259.7 407.8c-2.4 7.6-3.7 15.8-3.7 24.2c0 44.2 35.8 80 80 80s80-35.8 80-80c0-27.7-14-52.1-35.4-66.4l37.8-207.7zM156.3 232.2c2.2-6.9 3.5-14.2 3.7-21.7l183.8-73.5c3.6 3.5 7.4 6.7 11.6 9.5L317.6 354.1c-5.5 1.3-10.8 3.1-15.8 5.5L156.3 232.2z"
                      />
                    </svg>
                    <Link to="/rollout-services">
                      <h4>
                        Professional Commercial & Residential Rollout Services
                      </h4>
                    </Link>
                    <p>
                      Whether you're expanding, renovating or updating, count on
                      us for efficient rollout services that minimize
                      disruptions to your operations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <Link to="/services" className="more-link">
                  See More
                </Link>
              </div>
            </div>
          </div>
          {/*-/.row */}
        </div>
        {/*/.container */}
      </section>

      <section className="confacts-section">
        <div className="container">
          <div className="row">
            <div className="funfact_wapr">
              <div className="col-sm-3 col-xs-12">
                <div className="facts_border" />
                <div className="facts_wrapper">
                  <h3 className="stat-count count">200+</h3>
                  <h5>PROJECTS DONE</h5>
                </div>
              </div>
              <div className="col-sm-3 col-xs-12">
                <div className="facts_border facts_border_2" />
                <div className="facts_wrapper">
                  <h3 className="stat-count count">200+</h3>
                  <h5>PROBLEMS SOLVED</h5>
                </div>
              </div>
              <div className="col-sm-3 col-xs-12">
                <div className="facts_border" />
                <div className="facts_wrapper">
                  <h3 className="stat-count count">35+</h3>
                  <h5> CLIENTS HELPED</h5>
                </div>
              </div>
              <div className="col-sm-3 col-xs-12">
                <div className="facts_border facts_border_2" />
                <div className="facts_wrapper">
                  <h3 className="stat-count count">500+</h3>
                  <h5> Working Hours</h5>
                </div>
              </div>
              {/* col-lg-4 /- */}
            </div>
            {/* funfact_wapr /- */}
          </div>
          {/* row /- */}
        </div>
        {/* container /- */}
      </section>
      <div className="testimonials-section" id="testimo">
        <div className="container">
          {/* Start: Heading */}
          <div className="base-header">
            <h3>what our clients say</h3>
          </div>
          {/* End:  heading */}
          <div className="row">
            {/* Testimonials*/}
            <OwlCarousel
              className="owl-carousel owl-theme"
              id="testimonial"
              autoplay={false}
              smartSpeed={2000}
              items={2}
              loop
              margin={10}
              touchDrag
              mouseDrag
              nav={false}
              dots={false}
              responsive={{
                1200: {
                  items: 2,
                },
                992: {
                  items: 2,
                },
                768: {
                  items: 1,
                },
                480: {
                  items: 1,
                },
                320: {
                  items: 1,
                },
                280: {
                  items: 1,
                },
              }}
            >
              <div className="item">
                <div className="testimonial-box col-md-12">
                  <div className="testimonial_content">
                    <p>
                      I've been working with Nelz Management for a few years
                      now, and their professionalism and attention to detail
                      have never wavered. Their landscaping and maintenance
                      services have transformed our property into a welcoming
                      oasis. I highly recommend them!
                    </p>
                  </div>
                  <div className="cus-title">
                    <span className="tes-nam">John T</span>
                    <span className="tes-degree">Property Manager</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-box col-md-12">
                  <div className="testimonial_content">
                    <p>
                      We rely on Nelz Management for their janitorial services,
                      and they never disappoint. Our office space is
                      consistently clean and well-maintained, creating a
                      productive work environment. Their team is responsive and
                      accommodating – a true partner in our success.
                    </p>
                  </div>
                  <div className="cus-title">
                    <span className="tes-nam">Sarah L</span>
                    <span className="tes-degree">Office Manager</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-box col-md-12">
                  <div className="testimonial_content">
                    <p>
                      Nelz Management has been our go-to for emergency services.
                      They've helped us navigate everything from power outages
                      to unexpected repairs with efficiency and expertise. Their
                      24/7 support is invaluable to our operations.
                    </p>
                  </div>
                  <div className="cus-title">
                    <span className="tes-nam">David S</span>
                    <span className="tes-degree">Facilities Director</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial-box col-md-12">
                  <div className="testimonial_content">
                    <p>
                      When it comes to rollout services, Nelz Management is
                      second to none. They've managed multiple expansion
                      projects for us flawlessly. Their attention to detail and
                      project management skills make them an indispensable part
                      of our growth strategy.
                    </p>
                  </div>
                  <div className="cus-title">
                    <span className="tes-nam">Lisa H</span>
                    <span className="tes-degree">
                      Expansion Project Manager
                    </span>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
      <div className="estimate_section" id="estimate">
        <div className="container">
          <div className="row">
            <div className="col-md-offset-6 col-md-6 col-sm-6">
              <form
                id="contactForm"
                className="form-order"
                onSubmit={sendReview}
              >
                <div className="estimate_form_inner">
                  <h3>Drop a review</h3>
                  <div id="success" />
                  <div className="input-group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      required
                      className="form-control"
                      value={formData.name}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      required
                      className="form-control"
                      value={formData.email}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Phone No."
                      className="form-control"
                      required
                      value={formData.phone}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      name="occupation"
                      id="occupation"
                      placeholder="Occupation"
                      className="form-control"
                      value={formData.occupation}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="input-group input_group_full_width">
                    <textarea
                      name="review"
                      id="review"
                      placeholder="Review"
                      required
                      className="form-control"
                      rows={4}
                      value={formData.review}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  {loading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      Sending Review...
                    </button>
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Send Review
                    </button>
                  )}
                </div>
              </form>
              {/*/.form */}
            </div>
          </div>
          {/*/.row */}
        </div>
        {/*/.container */}
      </div>

      <div className="bottom_banner">
        <div className="container">
          <div className="col-md-6 flt_left">
            <h4>Keep Up To Date With Everything</h4>
            <p>We are Honest &amp; our work will make you satisified</p>
          </div>
          <div className="col-md-6">
            <div className="sub_form_warp">
              <div className="sub_form">
                <form onSubmit={subscribe}>
                  <input
                    placeholder="Your email address"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
      />
    </>
  );
};

export default Home;
