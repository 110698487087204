const HeaderTop = () => {
  return (
    <div className="header_top">
      <div className="container">
        <div className="row">
          <div className="header_top_location">
            <div className="header_top_left">
              <ul className="list-inline pull-left">
                <li style={{ fontSize: "17px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                    style={{ fill: "#038FD6", marginRight: "6px" }}
                  >
                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                  <a
                    href="https://www.google.com/maps/place/8990+Kirby+Dr,+Houston,+TX+77054,+USA/@29.6739306,-95.4152036,17z/data=!3m1!4b1!4m6!3m5!1s0x8640eaa19bc8b275:0x61d108c4e77a7015!8m2!3d29.673926!4d-95.4126287!16s%2Fg%2F11csk6m4jk?entry=ttu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    8990 Kirby Dr suite #220 Houston, Texas 77054, USA
                  </a>
                </li>
              </ul>
            </div>
            <div className="header_top_right list-unstyled">
              <ul>
                <li style={{ fontSize: "17px" }}>
                  <i className="fa fa-envelope" />
                  <a href="mailto:contact@nelzmgt.com">contact@nelzmgt.com</a>
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fa fa-phone" />
                  <a href="tel:3462322567">346-232-2567</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
