import { Link } from "react-router-dom";
import logo from "../assets/logo-dark.png";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-sm-5 col-xs-12">
            <div className="widget">
              <div className="col-md-12">
                <img className="img-responsive logo" src={logo} alt="logo" />
              </div>
              <div className="col-xs-1">
                <ul className="list-icons link-list footer_soc">
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=61552286722614&mibextid=LQQJ4d"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/nelzmgt?igsh=MXB2cDlkaW8xb2o3NA%3D%3D&utm_source=qr"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#/">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xs-10">
                <p className="footer_para">
                  Your trusted partner for a wide range of professional services
                  tailored to meet the unique needs of commercial and industrial
                  properties.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xs-12">
            <div className="widget">
              <ul className="recent-post helpful_post">
                <li>
                  <h6>
                    <Link to="/">Home</Link>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Link to="/about">About</Link>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Link to="/services">Services</Link>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Link to="/contact">Contact</Link>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Link to="/consultation">Get a Quote </Link>
                  </h6>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-sm-3 col-xs-12">
            <div className="widget">
              <h5>Contact info</h5>
              <div className="box-content">
                <div className="cont_widg">
                  <ul className="footer_contact_info">
                    <li>
                      <i className="icon_phone" />
                      <span>
                        <a href="tel:3462322567">346-232-2567</a>
                      </span>
                    </li>
                    <li>
                      <i className="icon_mail" />
                      <span>
                        <a href="mailto:contact@nelzmgt.com">
                          contact@nelzmgt.com
                        </a>
                      </span>
                    </li>
                    <li>
                      <i className="icon_pin" />
                      <span>
                        <a
                          href="https://www.google.com/maps/place/8990+Kirby+Dr,+Houston,+TX+77054,+USA/@29.6739306,-95.4152036,17z/data=!3m1!4b1!4m6!3m5!1s0x8640eaa19bc8b275:0x61d108c4e77a7015!8m2!3d29.673926!4d-95.4126287!16s%2Fg%2F11csk6m4jk?entry=ttu"
                          target="_blank"
                          rel="noreferrer"
                        >
                          8990 Kirby Dr suite #220 Houston, Texas 77054, USA
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="subfooter">
            <div className="col-xs-6">
              <p>
                {new Date().getFullYear()} © Copyright Nelz Management. Powered
                by{" "}
                <a
                  href="https://www.arvotek.org"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "#71cee8" }}
                >
                  Arvotek
                </a>
              </p>
            </div>
            <div className="col-xs-6">
              <a className="scrollup" href="#top">
                <i className="arrow_carrot-up" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
