import { FormEventHandler, useState } from "react";
import HeaderTop from "../components/HeaderTop";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import logo from "../assets/logo-dark.png";

const Contact = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const inputChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const sendMessage: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axios.post("https://app.nelzmgt.com/api/message", {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      });

      toast.success("Message sent successfully!");
      setLoading(false);
      setFormData((prevState) => ({
        ...prevState,
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      }));
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const subscribe: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://app.nelzmgt.com/api/mailing-list", {
        email,
      });

      toast.success("Email address added to mailing list!");
      setEmail("");
    } catch (error: any) {
      if (error.response.data.error === "This user already exists") {
        toast.error("Email address already in mailing list");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <HeaderTop />
      <div className="navigation">
        <div className="container">
          <div className="row">
            <div className="header_logo">
              <Link to="/">
                <img src={logo} alt="logo" className="logo" />
              </Link>
            </div>
            <div className="mobile-screen">
              <div id="navigation" className="small-screen">
                <div
                  id="menu-button"
                  className={`${menuOpen ? "menu-opened" : ""}`}
                  onClick={toggleMenu}
                />
                <ul
                  style={menuOpen ? { display: "block" } : { display: "none" }}
                  className={`${menuOpen ? "open" : ""}`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link className="active-link" to="/contact">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/consultation">Get Quote</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="desktop-screen">
              <div id="navigation">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link className="active-link" to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-right">
              <Link to="/consultation" className="header_quate_right">
                Get a Quote{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <header id="page-top" className="blog-banner">
        <div className="container" id="blog">
          <div
            className="row blog-header text-center wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="col-sm-12">
              <h4>
                <Link to="/"> Home </Link> / Contact{" "}
              </h4>
              <h3>Contact Us</h3>
            </div>
          </div>
        </div>
      </header>
      <section className="contact-section">
        <div className="container">
          <div className="base-header">
            <h3>Contact Us </h3>
          </div>
          <div className="inner-contact">
            <div className="row">
              <div className="map_warp col-lg-12">
                <div className="map-container">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3466.581003207021!2d-95.41520362450292!3d29.673930636030658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640eaa19bc8b275%3A0x61d108c4e77a7015!2s8990%20Kirby%20Dr%2C%20Houston%2C%20TX%2077054%2C%20USA!5e0!3m2!1sen!2sng!4v1696873714617!5m2!1sen!2sng"
                    width="800"
                    height="600"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-xs-12 contact_warp">
                <div className="contact-form">
                  <form id="contact-form" onSubmit={sendMessage}>
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          className="con-field"
                          name="name"
                          id="name"
                          type="text"
                          placeholder="Full Name"
                          required
                          value={formData.name}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="con-field"
                          name="phone"
                          id="phone"
                          type="tel"
                          placeholder="Phone"
                          required
                          value={formData.phone}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                      <div className="col-lg-12">
                        <input
                          className="con-field"
                          name="email"
                          id="email"
                          type="email"
                          placeholder="Email"
                          required
                          value={formData.email}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <textarea
                          className="con-field"
                          name="message"
                          id="message"
                          rows={6}
                          placeholder="Your Message"
                          required
                          value={formData.message}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />
                        <div className="submit-area">
                          {loading ? (
                            <button
                              type="button"
                              id="submit-contact"
                              className="btn-alt"
                            >
                              Sending Message...
                            </button>
                          ) : (
                            <button
                              type="submit"
                              id="submit-contact"
                              className="btn-alt"
                            >
                              Send Message
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bottom_banner">
        <div className="container">
          <div className="col-md-6 flt_left">
            <h4>Keep Up To Date With Everything</h4>
            <p>We are Honest &amp; our work will make you satisified</p>
          </div>
          <div className="col-md-6">
            <div className="sub_form_warp">
              <div className="sub_form">
                <form onSubmit={subscribe}>
                  <input
                    placeholder="Your email address"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
      />
    </>
  );
};

export default Contact;
