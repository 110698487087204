import { FormEventHandler, useState } from "react";
import HeaderTop from "../components/HeaderTop";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import logo from "../assets/logo-dark.png";

const Consultation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
  });

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const inputChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendMessage: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axios.post("https://app.nelzmgt.com/api/consultation", {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        service: formData.service,
        message: formData.message,
      });

      toast.success("Message sent successfully!");
      setLoading(false);
      setFormData((prevState) => ({
        ...prevState,
        name: "",
        email: "",
        phone: "",
        service: "",
        message: "",
      }));
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <HeaderTop />
      <div className="navigation">
        <div className="container">
          <div className="row">
            <div className="header_logo">
              <Link to="/">
                <img src={logo} alt="logo" className="logo" />
              </Link>
            </div>
            <div className="mobile-screen">
              <div id="navigation" className="small-screen">
                <div
                  id="menu-button"
                  className={`${menuOpen ? "menu-opened" : ""}`}
                  onClick={toggleMenu}
                />
                <ul
                  style={menuOpen ? { display: "block" } : { display: "none" }}
                  className={`${menuOpen ? "open" : ""}`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link className="active-link" to="/consultation">
                      Get Quote
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="desktop-screen">
              <div id="navigation">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-right">
              <Link to="/consultation" className="header_quate_right">
                Get a Quote{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="estimate_section" id="estimate">
        <div className="container">
          <div className="row">
            <div className="col-md-offset-6 col-md-6 col-sm-6">
              <form
                id="contactForm"
                className="form-order"
                onSubmit={sendMessage}
              >
                <div className="estimate_form_inner">
                  <h3>Get a Quote</h3>
                  <div id="success" />
                  <div className="input-group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      required
                      className="form-control"
                      value={formData.name}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      required
                      className="form-control"
                      value={formData.email}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Phone No."
                      className="form-control"
                      required
                      value={formData.phone}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      name="service"
                      id="service"
                      placeholder="Interest Of Service"
                      className="form-control"
                      required
                      value={formData.service}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  <div className="input-group input_group_full_width">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message"
                      required
                      className="form-control"
                      rows={4}
                      value={formData.message}
                      onChange={(e) => {
                        inputChange(e);
                      }}
                    />
                  </div>
                  {loading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      Sending...
                    </button>
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Send
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
      />
    </>
  );
};

export default Consultation;
