import { FormEventHandler, useState } from "react";
import HeaderTop from "../components/HeaderTop";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import logo from "../assets/logo-dark.png";
import about from "../assets/about.jpg";

const About = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [email, setEmail] = useState("");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const subscribe: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://app.nelzmgt.com/api/mailing-list", {
        email,
      });

      toast.success("Email address added to mailing list!");
      setEmail("");
    } catch (error: any) {
      if (error.response.data.error === "This user already exists") {
        toast.error("Email address already in mailing list");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <HeaderTop />
      <div className="navigation">
        <div className="container">
          <div className="row">
            <div className="header_logo">
              <Link to="/">
                <img src={logo} alt="logo" className="logo" />
              </Link>
            </div>
            <div className="mobile-screen">
              <div id="navigation" className="small-screen">
                <div
                  id="menu-button"
                  className={`${menuOpen ? "menu-opened" : ""}`}
                  onClick={toggleMenu}
                />
                <ul
                  style={menuOpen ? { display: "block" } : { display: "none" }}
                  className={`${menuOpen ? "open" : ""}`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link className="active-link" to="/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/consultation">Get Quote</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="desktop-screen">
              <div id="navigation">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link className="active-link" to="/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-right">
              <Link to="/consultation" className="header_quate_right">
                Get a Quote{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <header id="page-top" className="blog-banner">
        <div className="container" id="blog">
          <div
            className="row blog-header text-center wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="col-sm-12">
              <h4>
                <Link to="/"> Home </Link> / About{" "}
              </h4>
              <h3>About Us</h3>
            </div>
          </div>
        </div>
      </header>

      <section className="about_page_section">
        <div className="container">
          <div className="base-header">
            <h3>We Are Nelz Management</h3>
            <p>
              Welcome to Nelz Management, your trusted partner for a wide range
              of professional services tailored to meet the unique needs of
              commercial and industrial properties.
            </p>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <img src={about} alt="about" />
            </div>
            <div className="col-sm-6 col-xs-12">
              <h2>ABOUT OUR COMPANY</h2>
              <p>
                We are your trusted partners for a wide range of professional
                services tailored to meet the unique needs of commercial and
                industrial properties. With a proven track record of excellence,
                we have been providing top-tier services in the areas of
                professional commercial landscaping, property maintenance,
                professional commercial cleaning, janitorial, emergency and
                rollout services.
              </p>
              <p>
                At Nelz Management, we understand that the appearance and
                functionality of your commercial property are essential to your
                business's success. Our comprehensive suite of services is
                designed to enhance the beauty, safety, and efficiency of your
                property, ensuring it leaves a lasting impression on your
                clients, tenants and employees.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="bottom_banner">
        <div className="container">
          <div className="col-md-6 flt_left">
            <h4>Keep Up To Date With Everything</h4>
            <p>We are Honest &amp; our work will make you satisified</p>
          </div>
          <div className="col-md-6">
            <div className="sub_form_warp">
              <div className="sub_form">
                <form onSubmit={subscribe}>
                  <input
                    placeholder="Your email address"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
      />
    </>
  );
};

export default About;
