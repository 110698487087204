import { FormEventHandler, useState } from "react";
import HeaderTop from "../components/HeaderTop";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import logo from "../assets/logo-dark.png";
import landscaping from "../assets/landscaping.jpg";

const Landscaping = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [email, setEmail] = useState("");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const subscribe: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://app.nelzmgt.com/api/mailing-list", {
        email,
      });

      toast.success("Email address added to mailing list!");
      setEmail("");
    } catch (error: any) {
      if (error.response.data.error === "This user already exists") {
        toast.error("Email address already in mailing list");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <HeaderTop />
      <div className="navigation">
        <div className="container">
          <div className="row">
            <div className="header_logo">
              <Link to="/">
                <img src={logo} alt="logo" className="logo" />
              </Link>
            </div>
            <div className="mobile-screen">
              <div id="navigation" className="small-screen">
                <div
                  id="menu-button"
                  className={`${menuOpen ? "menu-opened" : ""}`}
                  onClick={toggleMenu}
                />
                <ul
                  style={menuOpen ? { display: "block" } : { display: "none" }}
                  className={`${menuOpen ? "open" : ""}`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link className="active-link" to="/services">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/consultation">Get Quote</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="desktop-screen">
              <div id="navigation">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link className="active-link" to="/services">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-right">
              <Link to="/consultation" className="header_quate_right">
                Get a Quote{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <header id="page-top" className="blog-banner">
        <div className="container" id="blog">
          <div
            className="row blog-header text-center wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="col-sm-12">
              <h4>
                <Link to="/"> Home </Link> / Service{" "}
              </h4>
              <h3>Our Services</h3>
            </div>
          </div>
        </div>
      </header>
      <section className="single_service_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 single_service_side_bar">
              <div className="single_service_category">
                <ul>
                  <li className="active">
                    <Link
                      to="/professional-commercial-landscaping"
                      style={{ lineHeight: "1.2" }}
                    >
                      <i className="fa fa-angle-right" /> Professional
                      Commercial & Residential Landscaping | Property
                      Maintenance
                    </Link>
                  </li>
                  <li>
                    <Link to="/professional-commercial-cleaning">
                      <i className="fa fa-angle-right" />
                      Professional Commercial & Residential Cleaning |
                      Janitorial Services
                    </Link>
                  </li>

                  <li>
                    <Link to="/emergency-services">
                      <i className="fa fa-angle-right" />
                      Professional Commercial & Residential Emergency Services |
                      24/7 Proven Support
                    </Link>
                  </li>
                  <li>
                    <Link to="/rollout-services">
                      <i className="fa fa-angle-right" />
                      Professional Commercial & Residential Rollout Services
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="single_service_call">
                <p>
                  Have any Question ?
                  <br /> Call Us :
                </p>
                <a href="tel:3462322567">346-232-2567</a>
                <span>For Details</span>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 single_service_page_content">
              <img src={landscaping} alt="landscaping" />
              <div className="single_service_title">
                <h3>
                  Professional Commercial & Residential Landscaping | Property
                  Maintenance
                </h3>
                <p>
                  When it comes to enhancing the visual appeal and functionality
                  of your commercial property, look no further than Nelz
                  Management. Our Professional commercial & residential
                  landscaping and property maintenance services are designed to
                  elevate the beauty and functionality of your outdoor spaces
                  while ensuring the optimal condition of your property.
                </p>
              </div>
              <div className="single_service_title2">
                <p>
                  We believe that the exterior of your commercial property sets
                  the stage for the first impression. Our professional
                  commercial & residential landscaping and maintenance services
                  are tailored to create an inviting and well-maintained outdoor
                  environment that leaves a lasting mark on your clients,
                  tenants, and visitors.
                  <br />
                  We know that commercial, private and industrial property
                  owners have invested a lot of time and money in their
                  properties. That's why we strive to keep them looking their
                  best with specialized plans and attention for each property.
                </p>
              </div>
              <div className="single_service_title3">
                <h3>What's included in this service</h3>
                <ul className="list margin-top-10">
                  <li>Turf care</li>
                  <li>Installation of soils and mulchs</li>
                  <li>Tree and shrub care</li>
                  <li>Pruning services</li>
                  <li>Leaf blowing and raking</li>
                  <li>Irrigation installation and maintenance</li>
                  <li>Gardening services</li>
                  <li>Personalized solutions</li>
                </ul>
              </div>
              <div className="single_service_consult">
                <i>Interested in this or any other service?</i>
                <Link to="/consultation" className="more-link">
                  Get a Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bottom_banner">
        <div className="container">
          <div className="col-md-6 flt_left">
            <h4>Keep Up To Date With Everything</h4>
            <p>We are Honest &amp; our work will make you satisified</p>
          </div>
          <div className="col-md-6">
            <div className="sub_form_warp">
              <div className="sub_form">
                <form onSubmit={subscribe}>
                  <input
                    placeholder="Your email address"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
      />
    </>
  );
};

export default Landscaping;
