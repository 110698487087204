import { FormEventHandler, useState } from "react";
import HeaderTop from "../components/HeaderTop";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import logo from "../assets/logo-dark.png";
import emergency from "../assets/banner-3.jpg";

const Emergency = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [email, setEmail] = useState("");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const subscribe: FormEventHandler = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://app.nelzmgt.com/api/mailing-list", {
        email,
      });

      toast.success("Email address added to mailing list!");
      setEmail("");
    } catch (error: any) {
      if (error.response.data.error === "This user already exists") {
        toast.error("Email address already in mailing list");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <HeaderTop />
      <div className="navigation">
        <div className="container">
          <div className="row">
            <div className="header_logo">
              <Link to="/">
                <img src={logo} alt="logo" className="logo" />
              </Link>
            </div>
            <div className="mobile-screen">
              <div id="navigation" className="small-screen">
                <div
                  id="menu-button"
                  className={`${menuOpen ? "menu-opened" : ""}`}
                  onClick={toggleMenu}
                />
                <ul
                  style={menuOpen ? { display: "block" } : { display: "none" }}
                  className={`${menuOpen ? "open" : ""}`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link className="active-link" to="/services">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/consultation">Get Quote</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="desktop-screen">
              <div id="navigation">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link className="active-link" to="/services">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-right">
              <Link to="/consultation" className="header_quate_right">
                Get a Quote{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <header id="page-top" className="blog-banner">
        <div className="container" id="blog">
          <div
            className="row blog-header text-center wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="col-sm-12">
              <h4>
                <Link to="/"> Home </Link> / Service{" "}
              </h4>
              <h3>Our Services</h3>
            </div>
          </div>
        </div>
      </header>
      <section className="single_service_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 single_service_side_bar">
              <div className="single_service_category">
                <ul>
                  <li>
                    <Link to="/professional-commercial-landscaping">
                      <i className="fa fa-angle-right" /> Professional
                      Commercial & Residential Landscaping | Property
                      Maintenance
                    </Link>
                  </li>
                  <li>
                    <Link to="/professional-commercial-cleaning">
                      <i className="fa fa-angle-right" />
                      Professional Commercial & Residential Cleaning |
                      Janitorial Services
                    </Link>
                  </li>

                  <li className="active">
                    <Link to="/emergency-services">
                      <i className="fa fa-angle-right" />
                      Professional Commercial & Residential Emergency Services |
                      24/7 Proven Support
                    </Link>
                  </li>
                  <li>
                    <Link to="/rollout-services">
                      <i className="fa fa-angle-right" />
                      Professional Commercial & Residential Rollout Services
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="single_service_call">
                <p>
                  Have any Question ?
                  <br /> Call Us :
                </p>
                <a href="tel:3462322567">346-232-2567</a>
                <span>For Details</span>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 single_service_page_content">
              <img src={emergency} alt="janitor" />
              <div className="single_service_title">
                <h3>
                  Professional Commercial & Residential Emergency Services |
                  24/7 Proven Support
                </h3>
                <p>
                  Emergencies can strike at any moment, and when they do, you
                  need a reliable partner by your side. At Nelz Management, we
                  offer comprehensive emergency Services and 24/7 Proven Support
                  to ensure your commercial property remains secure,
                  operational, and ready to handle unexpected situations.
                </p>
                <p>
                  Our clients can reach us at any time – day or night. Our
                  active response keeps facilities on track and operational with
                  little to no disruption to other sections, relieving stress on
                  valuable facility resources. By being an integral part of
                  facility operations, we have the knowledge and expertise to
                  meet the special demands of emergency situations.
                </p>
              </div>
              <div className="single_service_title2">
                <h3>Why Choose Our Emergency Services</h3>
                <p>
                  Rapid Response: When emergencies occur, every second counts.
                  Our dedicated team is on call 24/7, ready to respond swiftly
                  to any situation.
                  <br />
                  Peace of Mind: Knowing that you have a trusted partner
                  providing round-the-clock support offers peace of mind. Focus
                  on your business while we handle the unexpected.
                </p>
              </div>
              <div className="single_service_title3">
                <h3>Emergency situations we take care of</h3>
                <ul className="list margin-top-10">
                  <li>Faulty/non-functional furnace</li>
                  <li>Gas leaks</li>
                  <li>Water system leaks</li>
                  <li>Computer server rooms overheating</li>
                  <li>Flooding</li>
                  <li>Fume hood systems(alarm)</li>
                  <li>Manholes/sewers backed up</li>
                  <li>Faulty coolers, refrigerators, cold rooms etc.</li>
                  <li>Fluids cleanup</li>
                  <li>Faulty elevators</li>
                  <li>Insecure doors and windows</li>
                  <li>Power/electricity issues</li>
                  <li>Broken/cracked glass</li>
                  <li>Hazardous materials spills</li>
                  <li>Water disruption failure</li>
                  <li>Faulty card access systems</li>
                </ul>
              </div>
              <div className="single_service_consult">
                <i>Interested in this or any other service?</i>
                <Link to="/consultation" className="more-link">
                  Get a Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bottom_banner">
        <div className="container">
          <div className="col-md-6 flt_left">
            <h4>Keep Up To Date With Everything</h4>
            <p>We are Honest &amp; our work will make you satisified</p>
          </div>
          <div className="col-md-6">
            <div className="sub_form_warp">
              <div className="sub_form">
                <form onSubmit={subscribe}>
                  <input
                    placeholder="Your email address"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button>subscribe</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
      />
    </>
  );
};

export default Emergency;
