import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Cleaning from "./pages/Cleaning";
import Landscaping from "./pages/Landscaping";
import Emergency from "./pages/Emergency";
import Rollout from "./pages/Rollout";
import Consultation from "./pages/Consultation";
import axios from "axios";

const App = () => {
  useEffect(() => {
    const get = async () => {
      await axios.get("https://app.nelzmgt.com/api/mailing-list");
    };

    get();
  }, []);

  return (
    <div className="boxed_wrapper">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/consultation" element={<Consultation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/emergency-services" element={<Emergency />} />
        <Route
          path="/professional-commercial-cleaning"
          element={<Cleaning />}
        />
        <Route
          path="/professional-commercial-landscaping"
          element={<Landscaping />}
        />
        <Route path="/rollout-services" element={<Rollout />} />
        <Route path="/services" element={<Services />} />
      </Routes>
    </div>
  );
};

export default App;
